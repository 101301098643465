export const cityState = {
    "N": [
        "New York",
        "New York, NY",
        "New Rochelle, NY",
        "Niagara Falls, NY",
        "Norwalk, CA",
        "Newport Beach, CA",
        "Napa, CA",
        "National City, CA",
        "Novato, CA",
        "Newark, CA",
        "Naperville, IL",
        "Normal, IL",
        "North Richland Hills, TX",
        "New Braunfels, TX",
        "North Miami, FL",
        "North Port, FL",
        "North Miami Beach, FL",
        "North Lauderdale, FL",
        "Noblesville, IN",
        "Newark, OH",
        "North Carolina",
        "Novi, MI",
        "Nashville-Davidson, TN",
        "New Bedford, MA",
        "Newton, MA",
        "Northglenn, CO",
        "Norman, OK",
        "New Berlin, WI",
        "Nevada",
        "North Las Vegas, NV",
        "New Mexico",
        "Norfolk, VA",
        "Newport News, VA",
        "Nebraska",
        "New Orleans, LA",
        "New Jersey",
        "Newark, NJ",
        "New Brunswick, NJ",
        "Nampa, ID",
        "North Little Rock, AR",
        "New Haven, CT",
        "Norwalk, CT",
        "New Britain, CT",
        "Norwich, CT",
        "North Charleston, SC",
        "New Hampshire",
        "Nashua, NH",
        "North Dakota"
    ],
    "B": [
        "Buffalo, NY",
        "Binghamton, NY",
        "Bakersfield, CA",
        "Berkeley, CA",
        "Burbank, CA",
        "Buena Park, CA",
        "Bellflower, CA",
        "Baldwin Park, CA",
        "Brentwood, CA",
        "Bell Gardens, CA",
        "Brea, CA",
        "Beaumont, CA",
        "Bloomington, IL",
        "Bolingbrook, IL",
        "Berwyn, IL",
        "Belleville, IL",
        "Buffalo Grove, IL",
        "Bartlett, IL",
        "Brownsville, TX",
        "Beaumont, TX",
        "Bryan, TX",
        "Baytown, TX",
        "Bedford, TX",
        "Burleson, TX",
        "Bethlehem, PA",
        "Buckeye, AZ",
        "Bullhead City, AZ",
        "Boca Raton, FL",
        "Boynton Beach, FL",
        "Bradenton, FL",
        "Bonita Springs, FL",
        "Bloomington, IN",
        "Beavercreek, OH",
        "Burlington, NC",
        "Battle Creek, MI",
        "Bartlett, TN",
        "Brentwood, TN",
        "Boston, MA",
        "Brockton, MA",
        "Barnstable Town, MA",
        "Beverly, MA",
        "Bellevue, WA",
        "Bellingham, WA",
        "Burien, WA",
        "Bremerton, WA",
        "Boulder, CO",
        "Broomfield, CO",
        "Baltimore, MD",
        "Bowie, MD",
        "Bowling Green, KY",
        "Beaverton, OR",
        "Bend, OR",
        "Broken Arrow, OK",
        "Brookfield, WI",
        "Beloit, WI",
        "Blue Springs, MO",
        "Blacksburg, VA",
        "Bellevue, NE",
        "Bloomington, MN",
        "Brooklyn Park, MN",
        "Burnsville, MN",
        "Blaine, MN",
        "Baton Rouge, LA",
        "Bossier City, LA",
        "Bayonne, NJ",
        "Boise City, ID",
        "Birmingham, AL",
        "Bentonville, AR",
        "Bountiful, UT",
        "Biloxi, MS",
        "Bridgeport, CT",
        "Bristol, CT",
        "Bismarck, ND",
        "Billings, MT",
        "Bozeman, MT",
        "Burlington, VT"
    ],
    "R": [
        "Rochester, NY",
        "Riverside, CA",
        "Rancho Cucamonga, CA",
        "Roseville, CA",
        "Richmond, CA",
        "Rialto, CA",
        "Redding, CA",
        "Redwood City, CA",
        "Redlands, CA",
        "Redondo Beach, CA",
        "Rancho Cordova, CA",
        "Rocklin, CA",
        "Rosemead, CA",
        "Rancho Santa Margarita, CA",
        "Rancho Palos Verdes, CA",
        "Rohnert Park, CA",
        "Rockford, IL",
        "Romeoville, IL",
        "Rock Island, IL",
        "Round Rock, TX",
        "Richardson, TX",
        "Rowlett, TX",
        "Rockwall, TX",
        "Reading, PA",
        "Raleigh, NC",
        "Rocky Mount, NC",
        "Rochester Hills, MI",
        "Royal Oak, MI",
        "Roseville, MI",
        "Revere, MA",
        "Renton, WA",
        "Redmond, WA",
        "Richland, WA",
        "Rockville, MD",
        "Racine, WI",
        "Reno, NV",
        "Rio Rancho, NM",
        "Roswell, NM",
        "Richmond, VA",
        "Roanoke, VA",
        "Roswell, GA",
        "Rochester, MN",
        "Rogers, AR",
        "Riverton, UT",
        "Roy, UT",
        "Rhode Island",
        "Rapid City, SD",
        "Rock Hill, SC"
    ],
    "Y": [
        "Yonkers, NY",
        "Yorba Linda, CA",
        "Yuba City, CA",
        "Yucaipa, CA",
        "York, PA",
        "Yuma, AZ",
        "Youngstown, OH",
        "Yakima, WA"
    ],
    "S": [
        "Syracuse, NY",
        "Schenectady, NY",
        "San Diego, CA",
        "San Jose, CA",
        "San Francisco, CA",
        "Sacramento, CA",
        "Santa Ana, CA",
        "Stockton, CA",
        "San Bernardino, CA",
        "Santa Clarita, CA",
        "Santa Rosa, CA",
        "Salinas, CA",
        "Sunnyvale, CA",
        "Simi Valley, CA",
        "Santa Clara, CA",
        "San Buenaventura (Ventura), CA",
        "Santa Maria, CA",
        "San Mateo, CA",
        "South Gate, CA",
        "Santa Monica, CA",
        "Santa Barbara, CA",
        "San Leandro, CA",
        "San Marcos, CA",
        "San Ramon, CA",
        "South San Francisco, CA",
        "San Clemente, CA",
        "Santa Cruz, CA",
        "San Rafael, CA",
        "Santee, CA",
        "San Luis Obispo, CA",
        "San Jacinto, CA",
        "San Bruno, CA",
        "San Gabriel, CA",
        "Stanton, CA",
        "Springfield, IL",
        "Schaumburg, IL",
        "Skokie, IL",
        "Streamwood, IL",
        "San Antonio, TX",
        "San Angelo, TX",
        "Sugar Land, TX",
        "San Marcos, TX",
        "Sherman, TX",
        "Scranton, PA",
        "State College, PA",
        "Scottsdale, AZ",
        "Surprise, AZ",
        "Sierra Vista, AZ",
        "St. Petersburg, FL",
        "Sunrise, FL",
        "Sanford, FL",
        "Sarasota, FL",
        "St. Cloud, FL",
        "South Bend, IN",
        "Springfield, OH",
        "Strongsville, OH",
        "Sterling Heights, MI",
        "Southfield, MI",
        "St. Clair Shores, MI",
        "Saginaw, MI",
        "Smyrna, TN",
        "Springfield, MA",
        "Somerville, MA",
        "Salem, MA",
        "Seattle, WA",
        "Spokane, WA",
        "Spokane Valley, WA",
        "Shoreline, WA",
        "Sammamish, WA",
        "Salem, OR",
        "Springfield, OR",
        "Stillwater, OK",
        "Sheboygan, WI",
        "Sparks, NV",
        "Santa Fe, NM",
        "St. Louis, MO",
        "Springfield, MO",
        "St. Joseph, MO",
        "St. Charles, MO",
        "St. Peters, MO",
        "Suffolk, VA",
        "Savannah, GA",
        "Sandy Springs, GA",
        "Smyrna, GA",
        "St. Paul, MN",
        "St. Cloud, MN",
        "St. Louis Park, MN",
        "Shakopee, MN",
        "Shawnee, KS",
        "Salina, KS",
        "Shreveport, LA",
        "Sayreville, NJ",
        "Sioux City, IA",
        "Springdale, AR",
        "Salt Lake City, UT",
        "Sandy, UT",
        "St. George, UT",
        "South Jordan, UT",
        "Southaven, MS",
        "South Dakota",
        "Sioux Falls, SD",
        "Stamford, CT",
        "Shelton, CT",
        "South Carolina",
        "Summerville, SC",
        "Sumter, SC",
        "Spartanburg, SC"
    ],
    "A": [
        "Albany, NY",
        "Anaheim, CA",
        "Antioch, CA",
        "Alhambra, CA",
        "Alameda, CA",
        "Apple Valley, CA",
        "Arcadia, CA",
        "Aliso Viejo, CA",
        "Azusa, CA",
        "Aurora, IL",
        "Arlington Heights, IL",
        "Addison, IL",
        "Austin, TX",
        "Arlington, TX",
        "Amarillo, TX",
        "Abilene, TX",
        "Allen, TX",
        "Allentown, PA",
        "Altoona, PA",
        "Arizona",
        "Avondale, AZ",
        "Apache Junction, AZ",
        "Apopka, FL",
        "Altamonte Springs, FL",
        "Aventura, FL",
        "Anderson, IN",
        "Akron, OH",
        "Asheville, NC",
        "Apex, NC",
        "Ann Arbor, MI",
        "Attleboro, MA",
        "Auburn, WA",
        "Aurora, CO",
        "Arvada, CO",
        "Annapolis, MD",
        "Albany, OR",
        "Appleton, WI",
        "Albuquerque, NM",
        "Alexandria, VA",
        "Atlanta, GA",
        "Augusta-Richmond County, GA",
        "Athens-Clarke County, GA",
        "Albany, GA",
        "Alpharetta, GA",
        "Apple Valley, MN",
        "Alexandria, LA",
        "Alaska",
        "Anchorage, AK",
        "Atlantic City, NJ",
        "Alabama",
        "Auburn, AL",
        "Ames, IA",
        "Ankeny, IA",
        "Arkansas"
    ],
    "M": [
        "Mount Vernon, NY",
        "Modesto, CA",
        "Moreno Valley, CA",
        "Murrieta, CA",
        "Mission Viejo, CA",
        "Menifee, CA",
        "Merced, CA",
        "Mountain View, CA",
        "Manteca, CA",
        "Milpitas, CA",
        "Montebello, CA",
        "Madera, CA",
        "Monterey Park, CA",
        "Morgan Hill, CA",
        "Montclair, CA",
        "Monrovia, CA",
        "Martinez, CA",
        "Mount Prospect, IL",
        "Moline, IL",
        "McKinney, TX",
        "Mesquite, TX",
        "McAllen, TX",
        "Midland, TX",
        "Mission, TX",
        "Missouri City, TX",
        "Mansfield, TX",
        "Mesa, AZ",
        "Maricopa, AZ",
        "Marana, AZ",
        "Miami, FL",
        "Miramar, FL",
        "Miami Gardens, FL",
        "Miami Beach, FL",
        "Melbourne, FL",
        "Margate, FL",
        "Muncie, IN",
        "Mishawaka, IN",
        "Middletown, OH",
        "Mansfield, OH",
        "Mentor, OH",
        "Marion, OH",
        "Michigan",
        "Midland, MI",
        "Muskegon, MI",
        "Memphis, TN",
        "Murfreesboro, TN",
        "Massachusetts",
        "Malden, MA",
        "Medford, MA",
        "Methuen, MA",
        "Marlborough, MA",
        "Marysville, WA",
        "Maryland",
        "Medford, OR",
        "Moore, OK",
        "Midwest City, OK",
        "Muskogee, OK",
        "Milwaukee, WI",
        "Madison, WI",
        "Missouri",
        "Manassas, VA",
        "Macon, GA",
        "Marietta, GA",
        "Minnesota",
        "Minneapolis, MN",
        "Maple Grove, MN",
        "Minnetonka, MN",
        "Mankato, MN",
        "Maplewood, MN",
        "Moorhead, MN",
        "Manhattan, KS",
        "Monroe, LA",
        "Meridian, ID",
        "Montgomery, AL",
        "Mobile, AL",
        "Madison, AL",
        "Murray, UT",
        "Mississippi",
        "Meridian, MS",
        "Meriden, CT",
        "Milford, CT",
        "Middletown, CT",
        "Mount Pleasant, SC",
        "Manchester, NH",
        "Minot, ND",
        "Montana",
        "Missoula, MT",
        "Maine"
    ],
    "U": [
        "Utica, NY",
        "Upland, CA",
        "Union City, CA",
        "Urbana, IL",
        "Union City, NJ",
        "Urbandale, IA",
        "Utah"
    ],
    "W": [
        "White Plains, NY",
        "West Covina, CA",
        "Westminster, CA",
        "Whittier, CA",
        "Walnut Creek, CA",
        "Woodland, CA",
        "Watsonville, CA",
        "West Sacramento, CA",
        "Waukegan, IL",
        "Wheaton, IL",
        "Wheeling, IL",
        "Waco, TX",
        "Wichita Falls, TX",
        "Wylie, TX",
        "Weslaco, TX",
        "Wilkes-Barre, PA",
        "West Palm Beach, FL",
        "Weston, FL",
        "Wellington, FL",
        "Winter Garden, FL",
        "Warren, OH",
        "Westerville, OH",
        "Winston-Salem, NC",
        "Wilmington, NC",
        "Wilson, NC",
        "Warren, MI",
        "Westland, MI",
        "Wyoming, MI",
        "Worcester, MA",
        "Waltham, MA",
        "Weymouth Town, MA",
        "Westfield, MA",
        "Woburn, MA",
        "Washington",
        "Westminster, CO",
        "Washington, undefined",
        "Wisconsin",
        "Waukesha, WI",
        "West Allis, WI",
        "Wauwatosa, WI",
        "Wausau, WI",
        "Warner Robins, GA",
        "Woodbury, MN",
        "Wichita, KS",
        "West New York, NJ",
        "Waterloo, IA",
        "West Des Moines, IA",
        "West Valley City, UT",
        "West Jordan, UT",
        "Warwick, RI",
        "Woonsocket, RI",
        "Waterbury, CT",
        "West Haven, CT",
        "Wilmington, DE",
        "Wyoming",
        "West Virginia"
    ],
    "H": [
        "Hempstead, NY",
        "Huntington Beach, CA",
        "Hayward, CA",
        "Hesperia, CA",
        "Hawthorne, CA",
        "Hemet, CA",
        "Huntington Park, CA",
        "Hanford, CA",
        "Highland, CA",
        "Hoffman Estates, IL",
        "Hanover Park, IL",
        "Houston, TX",
        "Harlingen, TX",
        "Haltom City, TX",
        "Huntsville, TX",
        "Hurst, TX",
        "Harrisburg, PA",
        "Hialeah, FL",
        "Hollywood, FL",
        "Homestead, FL",
        "Hallandale Beach, FL",
        "Hammond, IN",
        "Hamilton, OH",
        "Huber Heights, OH",
        "High Point, NC",
        "Huntersville, NC",
        "Hickory, NC",
        "Hendersonville, TN",
        "Haverhill, MA",
        "Holyoke, MA",
        "Hagerstown, MD",
        "Hillsboro, OR",
        "Henderson, NV",
        "Hampton, VA",
        "Harrisonburg, VA",
        "Hutchinson, KS",
        "Hawaii",
        "Honolulu, HI",
        "Hoboken, NJ",
        "Hackensack, NJ",
        "Huntsville, AL",
        "Hoover, AL",
        "Hattiesburg, MS",
        "Hartford, CT",
        "Hilton Head Island, SC",
        "Huntington, WV"
    ],
    "T": [
        "Troy, NY",
        "Torrance, CA",
        "Thousand Oaks, CA",
        "Temecula, CA",
        "Tracy, CA",
        "Tustin, CA",
        "Turlock, CA",
        "Tulare, CA",
        "Tinley Park, IL",
        "Texas",
        "Tyler, TX",
        "Temple, TX",
        "Texas City, TX",
        "The Colony, TX",
        "Texarkana, TX",
        "Tucson, AZ",
        "Tempe, AZ",
        "Tampa, FL",
        "Tallahassee, FL",
        "Tamarac, FL",
        "Titusville, FL",
        "Terre Haute, IN",
        "Toledo, OH",
        "Troy, MI",
        "Taylor, MI",
        "Tennessee",
        "Taunton, MA",
        "Tacoma, WA",
        "Thornton, CO",
        "Tigard, OR",
        "Tulsa, OK",
        "Topeka, KS",
        "Trenton, NJ",
        "Twin Falls, ID",
        "Tuscaloosa, AL",
        "Taylorsville, UT"
    ],
    "F": [
        "Freeport, NY",
        "Fresno, CA",
        "Fremont, CA",
        "Fontana, CA",
        "Fullerton, CA",
        "Fairfield, CA",
        "Folsom, CA",
        "Fountain Valley, CA",
        "Fort Worth, TX",
        "Frisco, TX",
        "Flower Mound, TX",
        "Friendswood, TX",
        "Flagstaff, AZ",
        "Florida",
        "Fort Lauderdale, FL",
        "Fort Myers, FL",
        "Fort Pierce, FL",
        "Fort Wayne, IN",
        "Fishers, IN",
        "Fairfield, OH",
        "Findlay, OH",
        "Fayetteville, NC",
        "Flint, MI",
        "Farmington Hills, MI",
        "Franklin, TN",
        "Fall River, MA",
        "Fitchburg, MA",
        "Federal Way, WA",
        "Fort Collins, CO",
        "Frederick, MD",
        "Fond du Lac, WI",
        "Farmington, NM",
        "Florissant, MO",
        "Florence, AL",
        "Fort Smith, AR",
        "Fayetteville, AR",
        "Florence, SC",
        "Fargo, ND"
    ],
    "V": [
        "Valley Stream, NY",
        "Visalia, CA",
        "Victorville, CA",
        "Vallejo, CA",
        "Vista, CA",
        "Vacaville, CA",
        "Victoria, TX",
        "Vancouver, WA",
        "Virginia",
        "Virginia Beach, VA",
        "Valdosta, GA",
        "Vineland, NJ",
        "Vermont"
    ],
    "C": [
        "California",
        "Chula Vista, CA",
        "Corona, CA",
        "Concord, CA",
        "Costa Mesa, CA",
        "Carlsbad, CA",
        "Clovis, CA",
        "Compton, CA",
        "Carson, CA",
        "Chico, CA",
        "Citrus Heights, CA",
        "Chino, CA",
        "Chino Hills, CA",
        "Camarillo, CA",
        "Cupertino, CA",
        "Colton, CA",
        "Cathedral City, CA",
        "Cerritos, CA",
        "Cypress, CA",
        "Covina, CA",
        "Ceres, CA",
        "Coachella, CA",
        "Campbell, CA",
        "Culver City, CA",
        "Calexico, CA",
        "Chicago, IL",
        "Cicero, IL",
        "Champaign, IL",
        "Crystal Lake, IL",
        "Carol Stream, IL",
        "Carpentersville, IL",
        "Calumet City, IL",
        "Corpus Christi, TX",
        "Carrollton, TX",
        "College Station, TX",
        "Conroe, TX",
        "Cedar Park, TX",
        "Cedar Hill, TX",
        "Coppell, TX",
        "Chandler, AZ",
        "Casa Grande, AZ",
        "Cape Coral, FL",
        "Coral Springs, FL",
        "Clearwater, FL",
        "Coconut Creek, FL",
        "Coral Gables, FL",
        "Cutler Bay, FL",
        "Carmel, IN",
        "Columbus, IN",
        "Columbus, OH",
        "Cleveland, OH",
        "Cincinnati, OH",
        "Canton, OH",
        "Cuyahoga Falls, OH",
        "Cleveland Heights, OH",
        "Charlotte, NC",
        "Cary, NC",
        "Concord, NC",
        "Chapel Hill, NC",
        "Chattanooga, TN",
        "Clarksville, TN",
        "Collierville, TN",
        "Cleveland, TN",
        "Cambridge, MA",
        "Chicopee, MA",
        "Chelsea, MA",
        "Colorado",
        "Colorado Springs, CO",
        "Centennial, CO",
        "Castle Rock, CO",
        "Commerce City, CO",
        "Covington, KY",
        "Corvallis, OR",
        "Carson City, NV",
        "Clovis, NM",
        "Columbia, MO",
        "Chesterfield, MO",
        "Cape Girardeau, MO",
        "Chesapeake, VA",
        "Charlottesville, VA",
        "Columbus, GA",
        "Coon Rapids, MN",
        "Clifton, NJ",
        "Camden, NJ",
        "Caldwell, ID",
        "Coeur d'Alene, ID",
        "Cedar Rapids, IA",
        "Council Bluffs, IA",
        "Cedar Falls, IA",
        "Conway, AR",
        "Cranston, RI",
        "Connecticut",
        "Columbia, SC",
        "Charleston, SC",
        "Concord, NH",
        "Cheyenne, WY",
        "Casper, WY",
        "Charleston, WV"
    ],
    "L": [
        "Los Angeles, CA",
        "Long Beach, CA",
        "Lancaster, CA",
        "Livermore, CA",
        "Lakewood, CA",
        "Lake Forest, CA",
        "Lynwood, CA",
        "Laguna Niguel, CA",
        "Lodi, CA",
        "La Habra, CA",
        "La Mesa, CA",
        "Lake Elsinore, CA",
        "La Mirada, CA",
        "Lincoln, CA",
        "Lompoc, CA",
        "La Puente, CA",
        "La Quinta, CA",
        "Los Banos, CA",
        "Lombard, IL",
        "Laredo, TX",
        "Lubbock, TX",
        "Lewisville, TX",
        "League City, TX",
        "Longview, TX",
        "Lancaster, TX",
        "Lancaster, PA",
        "Lake Havasu City, AZ",
        "Lakeland, FL",
        "Largo, FL",
        "Lauderhill, FL",
        "Lafayette, IN",
        "Lawrence, IN",
        "Lorain, OH",
        "Lakewood, OH",
        "Lancaster, OH",
        "Lima, OH",
        "Lansing, MI",
        "Livonia, MI",
        "Lincoln Park, MI",
        "Lowell, MA",
        "Lynn, MA",
        "Lawrence, MA",
        "Leominster, MA",
        "Lakewood, WA",
        "Lacey, WA",
        "Lakewood, CO",
        "Longmont, CO",
        "Loveland, CO",
        "Littleton, CO",
        "Louisville/Jefferson County, KY",
        "Lexington-Fayette, KY",
        "Lake Oswego, OR",
        "Lawton, OK",
        "La Crosse, WI",
        "Las Vegas, NV",
        "Las Cruces, NM",
        "Lee's Summit, MO",
        "Lynchburg, VA",
        "Leesburg, VA",
        "Lincoln, NE",
        "Lakeville, MN",
        "Lawrence, KS",
        "Lenexa, KS",
        "Louisiana",
        "Lafayette, LA",
        "Lake Charles, LA",
        "Linden, NJ",
        "Little Rock, AR",
        "Layton, UT",
        "Lehi, UT",
        "Logan, UT"
    ],
    "O": [
        "Oakland, CA",
        "Oxnard, CA",
        "Oceanside, CA",
        "Ontario, CA",
        "Orange, CA",
        "Oakley, CA",
        "Orland Park, IL",
        "Oak Lawn, IL",
        "Oak Park, IL",
        "Odessa, TX",
        "Oro Valley, AZ",
        "Orlando, FL",
        "Ocala, FL",
        "Oakland Park, FL",
        "Ormond Beach, FL",
        "Ocoee, FL",
        "Ohio",
        "Olympia, WA",
        "Owensboro, KY",
        "Oregon",
        "Oklahoma",
        "Oklahoma City, OK",
        "Oshkosh, WI",
        "O'Fallon, MO",
        "Omaha, NE",
        "Overland Park, KS",
        "Olathe, KS",
        "Orem, UT",
        "Ogden, UT"
    ],
    "I": [
        "Irvine, CA",
        "Inglewood, CA",
        "Indio, CA",
        "Illinois",
        "Irving, TX",
        "Indiana",
        "Indianapolis, IN",
        "Independence, MO",
        "Idaho",
        "Idaho Falls, ID",
        "Iowa",
        "Iowa City, IA"
    ],
    "G": [
        "Glendale, CA",
        "Garden Grove, CA",
        "Gardena, CA",
        "Glendora, CA",
        "Gilroy, CA",
        "Glenview, IL",
        "Garland, TX",
        "Grand Prairie, TX",
        "Georgetown, TX",
        "Grapevine, TX",
        "Galveston, TX",
        "Glendale, AZ",
        "Gilbert, AZ",
        "Goodyear, AZ",
        "Gainesville, FL",
        "Greenacres, FL",
        "Gary, IN",
        "Greenwood, IN",
        "Grove City, OH",
        "Greensboro, NC",
        "Greenville, NC",
        "Gastonia, NC",
        "Goldsboro, NC",
        "Grand Rapids, MI",
        "Germantown, TN",
        "Greeley, CO",
        "Grand Junction, CO",
        "Gaithersburg, MD",
        "Gresham, OR",
        "Green Bay, WI",
        "Greenfield, WI",
        "Georgia",
        "Grand Island, NE",
        "Gadsden, AL",
        "Gulfport, MS",
        "Greenville, SC",
        "Goose Creek, SC",
        "Grand Forks, ND",
        "Great Falls, MT"
    ],
    "E": [
        "Elk Grove, CA",
        "Escondido, CA",
        "El Monte, CA",
        "El Cajon, CA",
        "Encinitas, CA",
        "Eastvale, CA",
        "El Centro, CA",
        "Elgin, IL",
        "Evanston, IL",
        "Elmhurst, IL",
        "El Paso, TX",
        "Edinburg, TX",
        "Euless, TX",
        "Erie, PA",
        "Evansville, IN",
        "Elkhart, IN",
        "Elyria, OH",
        "Euclid, OH",
        "East Lansing, MI",
        "Everett, MA",
        "Everett, WA",
        "Edmonds, WA",
        "Eugene, OR",
        "Edmond, OK",
        "Enid, OK",
        "Eau Claire, WI",
        "Eagan, MN",
        "Eden Prairie, MN",
        "Edina, MN",
        "Elizabeth, NJ",
        "East Orange, NJ",
        "East Providence, RI"
    ],
    "P": [
        "Palmdale, CA",
        "Pomona, CA",
        "Pasadena, CA",
        "Pleasanton, CA",
        "Perris, CA",
        "Palo Alto, CA",
        "Pittsburg, CA",
        "Pico Rivera, CA",
        "Petaluma, CA",
        "Porterville, CA",
        "Paramount, CA",
        "Placentia, CA",
        "Palm Desert, CA",
        "Poway, CA",
        "Palm Springs, CA",
        "Pacifica, CA",
        "Peoria, IL",
        "Palatine, IL",
        "Plainfield, IL",
        "Park Ridge, IL",
        "Plano, TX",
        "Pasadena, TX",
        "Pearland, TX",
        "Pharr, TX",
        "Port Arthur, TX",
        "Pflugerville, TX",
        "Pennsylvania",
        "Philadelphia, PA",
        "Pittsburgh, PA",
        "Phoenix, AZ",
        "Peoria, AZ",
        "Prescott, AZ",
        "Prescott Valley, AZ",
        "Port St. Lucie, FL",
        "Pembroke Pines, FL",
        "Palm Bay, FL",
        "Pompano Beach, FL",
        "Plantation, FL",
        "Palm Coast, FL",
        "Port Orange, FL",
        "Pensacola, FL",
        "Palm Beach Gardens, FL",
        "Pinellas Park, FL",
        "Portage, IN",
        "Parma, OH",
        "Pontiac, MI",
        "Portage, MI",
        "Peabody, MA",
        "Pittsfield, MA",
        "Pasco, WA",
        "Puyallup, WA",
        "Pueblo, CO",
        "Parker, CO",
        "Portland, OR",
        "Portsmouth, VA",
        "Plymouth, MN",
        "Paterson, NJ",
        "Passaic, NJ",
        "Perth Amboy, NJ",
        "Plainfield, NJ",
        "Pocatello, ID",
        "Pine Bluff, AR",
        "Provo, UT",
        "Providence, RI",
        "Pawtucket, RI",
        "Portland, ME"
    ],
    "D": [
        "Downey, CA",
        "Daly City, CA",
        "Davis, CA",
        "Diamond Bar, CA",
        "Delano, CA",
        "Dublin, CA",
        "Danville, CA",
        "Decatur, IL",
        "Des Plaines, IL",
        "Downers Grove, IL",
        "DeKalb, IL",
        "Dallas, TX",
        "Denton, TX",
        "DeSoto, TX",
        "Duncanville, TX",
        "Davie, FL",
        "Deltona, FL",
        "Deerfield Beach, FL",
        "Delray Beach, FL",
        "Daytona Beach, FL",
        "Doral, FL",
        "Dayton, OH",
        "Dublin, OH",
        "Durham, NC",
        "Detroit, MI",
        "Dearborn, MI",
        "Dearborn Heights, MI",
        "Denver, CO",
        "District of Columbia",
        "Danville, VA",
        "Dunwoody, GA",
        "Duluth, MN",
        "Dothan, AL",
        "Decatur, AL",
        "Des Moines, IA",
        "Davenport, IA",
        "Dubuque, IA",
        "Draper, UT",
        "Danbury, CT",
        "Delaware",
        "Dover, DE"
    ],
    "J": [
        "Jurupa Valley, CA",
        "Joliet, IL",
        "Jacksonville, FL",
        "Jupiter, FL",
        "Jeffersonville, IN",
        "Jacksonville, NC",
        "Jackson, TN",
        "Johnson City, TN",
        "Janesville, WI",
        "Joplin, MO",
        "Jefferson City, MO",
        "Johns Creek, GA",
        "Jersey City, NJ",
        "Jonesboro, AR",
        "Jackson, MS"
    ],
    "Q": [
        "Quincy, IL",
        "Quincy, MA"
    ],
    "K": [
        "Killeen, TX",
        "Keller, TX",
        "Kissimmee, FL",
        "Kokomo, IN",
        "Kettering, OH",
        "Kannapolis, NC",
        "Kalamazoo, MI",
        "Kentwood, MI",
        "Knoxville, TN",
        "Kingsport, TN",
        "Kent, WA",
        "Kennewick, WA",
        "Kirkland, WA",
        "Kentucky",
        "Keizer, OR",
        "Kenosha, WI",
        "Kansas City, MO",
        "Kansas",
        "Kansas City, KS",
        "Kenner, LA",
        "Kearny, NJ"
    ]
}